<!--
 * @Author: wangwei
 * @Date: 2020-12-28 16:06:28
 * @LastEditTime: 2023-01-17 20:11:04
 * @LastEditors: error: git config user.name && git config user.email & please set dead value or install git
 * @Description: In User Settings Edit
 * @FilePath: /java-pc/src/views/member/order/List.vue
-->
<template>
    <div>
        <div class="sld_order_list">
            <MemberTitle :memberTitle="L['新增采购计划']"></MemberTitle>
            <div class="container2">
                <el-form :model="addPlanData" ref="ruleFormRef" class="demo-ruleForm">
                    <div class="headerDev">
                        <h3>月度计划</h3>
                        <div style="width: 150px; display: flex">
                            <!--<el-form-item>-->

                            <el-button @click="goback">返回</el-button>
                            <div class="Submit" @click="onSubmit">提交</div>

                            <!--</el-form-item>-->
                        </div>
                    </div>

                    <div class="sld_order_nav">
                        <h3 style="margin: 20px 0px">{{ "基础信息" }}</h3>
                        <div>
                            <div style="display: flex; margin-bottom: 20px">
                                <el-form-item label="采购类型" prop="type">
                                    <el-radio-group v-model="addPlanData.type">
                                        <el-radio :label="0" size="large" style="color: #03499e">月度采购</el-radio>
                                    </el-radio-group>
                                </el-form-item>
                                <div style="display: flex; margin-left: 280px">
                                    <div
                                        style="
                                            margintop: 11px;
                                            margin-right: 5px;
                                            font-size: 16px;
                                            line-height: 40px;
                                            color: var(--el-color-danger);
                                        "
                                    >
                                        *
                                    </div>
                                    <el-form-item label="到货日期" prop="needDate">
                                        <el-date-picker
                                            v-model="addPlanData.needDate"
                                            type="date"
                                            placeholder="请选择到货日期"
                                            format="YYYY/MM/DD"
                                            value-format="YYYY-MM-DD"
                                        />
                                    </el-form-item>
                                </div>
                            </div>
                            <el-form-item label="备注" prop="desc">
                                <el-input type="textarea" v-model="addPlanData.desc" maxlength="500" show-word-limit />
                            </el-form-item>
                        </div>
                    </div>
                </el-form>
                <div>
                    <h3 style="margin: 20px 0px">{{ "采购商品" }}</h3>
                    <div>
                        <div>
                            <el-form
                                ref="formRef"
                                :label-width="auto"
                                label-position="top"
                                class="demo-dynamic"
                                :inline="true"
                                size="small"
                                v-for="(item, index) in goodsList"
                                :key="item.key"
                            >
                                <el-row :gutter="20">
                                    <el-col :span="4" style="padding-right: 0px">
                                        <el-form-item
                                            prop=""
                                            :label="'商品名称' + ++index"
                                            :rules="[
                                                {
                                                    required: true,
                                                    message: '请输入商品名称',
                                                    trigger: 'blur'
                                                }
                                            ]"
                                        >
                                            <!--style='width:195px'-->
                                            <el-input v-model="item.goodsName" maxlength="120" />
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="3" style="padding-right: 0px; padding-: 0px">
                                        <el-form-item
                                            :label="'规格'"
                                            :rules="{
                                                required: false,
                                                message: '请输入规格',
                                                trigger: 'blur'
                                            }"
                                        >
                                            <!--style='width:145px'-->
                                            <el-input v-model="item.goodsSpec" maxlength="30" />
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="3" style="padding-right: 0px">
                                        <el-form-item :label="'型号'">
                                            <!--style="width:145px"-->
                                            <el-input v-model="item.goodsMarque" maxlength="30" />
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="3" style="padding-right: 0px">
                                        <el-form-item
                                            label="计量单位"
                                            :rules="{
                                                required: true,
                                                message: '请选择单位',
                                                trigger: 'change'
                                            }"
                                        >
                                            <el-select v-model="item.goodsUnit" placeholder="单位">
                                                <el-option
                                                    :label="i.stateName"
                                                    v-for="(i, index) in unit.data"
                                                    :key="index"
                                                    :value="i.stateName"
                                                />

                                                <!--style="width:81px"-->
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="3" style="padding-right: 0px">
                                        <el-form-item label="数量">
                                            <el-input-number
                                                v-model="item.purchaseNum"
                                                :min="1"
                                                :max="9999"
                                                @change="handleChange"
                                            />
                                            <!--style="width:110px"-->
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="5" style="padding-right: 0px; padding-left: 0px">
                                        <el-form-item
                                            :label="'备注'"
                                            :rules="{
                                                required: false,
                                                message: 'domain can not be null',
                                                trigger: 'blur'
                                            }"
                                            :style="goodsList.length <= 1 ? 'width:400px' : 'width:300px'"
                                        >
                                            <!-- <el-input v-model="item.desc" maxlength="500" autosize show-word-limit type="textarea" /> -->
                                            <div style="position: relative">
                                                <el-input
                                                    v-model="item.desc"
                                                    :disabled="item.state == 3"
                                                    maxlength="500"
                                                    placeholder="请输入描述"
                                                    show-word-limit
                                                    type="textarea"
                                                />
                                                <!-- <i class="el-icon-plus" style="cursor: pointer;font-size: 20px;border: 1px dashed #c0ccda;position: absolute;top:8px;right: 10px;" @click="addDescImg(item)"></i> -->
                                                <span
                                                    class="clickBtn"
                                                    v-if="item.purchasePic.length > 0"
                                                    style="position: absolute; top: 4px; right: 10px"
                                                    @click="addDescImg(item)"
                                                    >查看</span
                                                >
                                                <i
                                                    class="el-icon-plus"
                                                    v-else
                                                    style="
                                                        cursor: pointer;
                                                        font-size: 20px;
                                                        border: 1px dashed #c0ccda;
                                                        position: absolute;
                                                        top: 8px;
                                                        right: 10px;
                                                    "
                                                    @click="addDescImg(item)"
                                                ></i>
                                            </div>
                                        </el-form-item>
                                    </el-col>

                                    <el-col :span="3">
                                        <div class="btnList">
                                            <!--<p class="choose" @click.prevent="openModal(item.key)">
                        选择商品
                      </p>-->
                                            <p
                                                class="delet"
                                                v-if="goodsList.length > 1"
                                                type="primary"
                                                @click.prevent="removeDomain(item.key)"
                                            >
                                                删除
                                            </p>
                                        </div>
                                    </el-col>
                                    <!--</el-col>-->
                                </el-row>
                            </el-form>

                            <div
                                class="addGoods"
                                @click="addDomain"
                                :dark="isDark"
                                type="primary"
                                plain
                                style="width: 100%"
                            >
                                +新增商品
                            </div>
                        </div>
                    </div>
                </div>
                <el-divider />
                <div class="sld_order_nav">
                    <div class="receive_info">
                        <div class="receive_info_title">
                            <span>{{ L["收货信息"] }}</span>
                        </div>
                        <div v-if="orderAddress.data.memberName" class="receive_info_content flex_row_start_center">
                            <div class="content_left flex_column_between_start">
                                <span class="member">收货人：{{ orderAddress.data.memberName }}</span>
                                <span>{{ L["联系方式"] }}：{{ orderAddress.data.telMobile }}</span>
                                <span
                                    :title="orderAddress.data.addressAll + orderAddress.data.detailAddress"
                                    class="address_detail overflow_ellipsis"
                                    >{{ L["收货地址"] }}：{{ orderAddress.data.addressAll }}
                                    {{ orderAddress.data.detailAddress }}</span
                                >
                            </div>
                            <div class="content_right flex_column_center_center">
                                <div class="replace_address pointer" @click="changeAddress">
                                    {{ L["更换地址"] }}
                                </div>
                                <div class="add_address pointer" @click="addAddress">
                                    {{ L["新建地址"] }}
                                </div>
                            </div>
                        </div>
                        <div v-else class="receive_info_content flex_row_center_center">
                            <div class="add_address nomartop pointer" @click="addAddress">
                                {{ L["新建地址"] }}
                            </div>
                        </div>
                    </div>
                    <!--<div>

                <h3>{{ "添加收货地址" }}</h3>
                <el-form-item label="收货人" prop="receivingName">
                  <el-input
                    style="width: 200px"
                    v-model="addPlanData.receivingName"
                  />
                </el-form-item>
                <el-form-item label="电话号码" prop="receivingMobile">
                  <el-input
                    style="width: 200px"
                    v-model="addPlanData.receivingMobile"
                  />
                </el-form-item>
                <el-form-item label="所在地区" prop="receivingAddressCode">
                  <el-cascader
                    @change="addressChange"
                    style="width: 200px"
                    v-model="form.data.area"
                    :props="cascaderProps"
                    :option="areaDataArray"
                    clearable

                  />
                </el-form-item>
                <el-form-item label="详细地址" prop="receivingAddress">
                  <el-input
                    type="textarea"
                    style="width: 250px"
                    v-model="addPlanData.receivingAddress"
                  />
                </el-form-item>
              </div>-->
                </div>
            </div>
        </div>
        <div>
            <el-dialog v-model="dialogVisible" title="选择商品" width="850px" :close-on-click-modal="false">
                <el-form :model="pageData" :inline="true" class="demo-form-inline" size="small">
                    <el-row :gutter="20" justify="start">
                        <el-form-item label="商品名称">
                            <el-input v-model="pageData.goodsName" />
                        </el-form-item>
                        <el-col :span="6">
                            <div style="display: flex; width: 180px">
                                <div class="search" style="margin-right: 10px" @click="onSearchGoods">搜索</div>
                                <div class="Clear2" @click="resetForm()">重置</div>
                            </div>
                        </el-col>
                    </el-row>
                </el-form>

                <el-table
                    ref="multipleTableRef"
                    :data="tableData.data"
                    width="700px"
                    max-height="700"
                    @selection-change="handleSelectionChange"
                >
                    <el-table-column type="selection" width="55" align="center" />
                    <el-table-column property="skuCode" label="大石sku" width="150" align="center" />
                    <!--<el-table-column
            property="supplierUserName"
            label="供应商"
            width="120"
          />-->
                    <!--<el-table-column property="brandName" label="品牌" />-->
                    <el-table-column property="skuName" label="商品名称" width="230" align="center" />
                    <!--<el-table-column property="marque" label="标准型号" width="90" />-->
                    <!--<el-table-column property="marketingUnit" label="单位" width="50" />-->
                    <el-table-column property="goodsPrice" label="单价" width="80" align="center" />
                    <el-table-column property="deliveryDay" label="预计发货时间" width="150" align="center" />
                    <el-table-column property="qualityDay" label="质保期" width="120" align="center" />
                </el-table>
                <el-pagination
                    style="margin-top: 20px"
                    :vi-if="pageData.total != 0"
                    v-model:current-page="pageData.pageNum"
                    v-model:page-size="pageData.pageSize"
                    :page-sizes="[5, 10, 20, 30]"
                    :small="small"
                    :disabled="disabled"
                    :background="background"
                    :total="pageData.total"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                />
                <template #footer>
                    <span style="text-align: right; display: flex; justify-content: flex-end">
                        <div class="Clear" @click="dialogVisible = false">取消</div>
                        <div class="Submit" @click="addGoods">确定</div>
                    </span>
                </template>
            </el-dialog>
        </div>
        <SldAddressOperation
            v-if="addressVisibleFlag"
            @close="close"
            @refreshAddress="refreshAddress"
            :visibleFlag="true"
            :type="type"
        />
        <!-- 地址选择弹窗 start -->
        <el-dialog
            title="更换地址"
            v-model="show_select_address"
            customClass="select_address_width"
            :before-close="handleClose"
            width="500px"
        >
            <div class="out_stock_dialog address_con">
                <div
                    v-for="(item, index) in address_list.data"
                    :key="index"
                    @click="selectAddress(index)"
                    :class="{
                        address_item: true,
                        flex_column_start_start: true,
                        select: current_address_index == index
                    }"
                >
                    <span>{{ L["收货人"] }}：{{ item.memberName }}</span>
                    <span>{{ L["联系方式"] }}：{{ item.telMobile }}</span>
                    <div class="address_text" :title="item.addressAll + item.detailAddress">
                        <span>{{ L["收货地址"] }}：</span>
                        <span class="overflow_ellipsis_clamp2">{{ item.addressAll }} {{ item.detailAddress }}</span>
                    </div>
                    <img v-if="current_address_index == index" class="selected" :src="invoice_selected" alt />
                </div>
            </div>
            <div class="btn_con flex_row_center_center">
                <div class="remove red" @click="confirmChangeAddress()">
                    {{ L["提交修改"] }}
                </div>
            </div>
        </el-dialog>
        <!-- 地址选择弹窗 end -->

        <!-- 备注图片上传弹窗 -->
        <el-dialog
            v-model="descImgVisible"
            :close-on-click-modal="false"
            :destroy-on-close="true"
            title="图片信息"
            width="820px"
        >
            <div>
                <el-upload
                    v-model:file-list="itemTableData.data.purchasePic"
                    :disabled="itemTableData.data.state == 3"
                    :action="uploadImgUrl"
                    :headers="{ Authorization: 'Bearer ' + access_token }"
                    list-type="picture-card"
                    :before-upload="beforeUpload"
                    :on-success="handlePictureCardSuccess(itemTableData.data)"
                    :on-preview="handlePictureCardPreview"
                    :on-remove="handleRemove"
                    :class="{
                        hide:
                            ([...itemTableData.data.purchasePic, ...itemAddImgData.data].length >= 5 &&
                                itemDelImgData.data.length == 0) ||
                            ([...itemTableData.data.purchasePic, ...itemAddImgData.data].length >= 5 &&
                                itemDelImgData.data.length != 0 &&
                                itemDelImgData.data.length >= 5),
                        'disabled-upload': itemTableData.data.state == 3
                    }"
                >
                    <i class="el-icon-plus"></i>
                </el-upload>

                <el-dialog v-model="dialogVisibleFull">
                    <img w-full :src="dialogImageUrl" alt="Preview Image" />
                </el-dialog>
            </div>
            <template #footer>
                <div class="dialog-footer">
                    <el-button @click="handleCloseDesc">关闭</el-button>
                    <el-button type="primary" @click="handleConfirmDesc">确认</el-button>
                </div>
            </template>
        </el-dialog>
    </div>
</template>
<script>
import { reactive, getCurrentInstance, ref, onMounted } from "vue";
//import moment from 'moment';
//import { Upload } from '@element-plus/icons-vue'
import { ElInput, ElMessage, ElMessageBox, ElTimeline, ElTimelineItem } from "element-plus";
// import { Search } from '@element-plus/icons-vue'
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import { apiUrl, apiUrlSub } from "../../../utils/config";
import axios from "axios";

//import {
//  Check,
//  Delete,
//  Edit,
//  Message,
//  Search,
//  Star,
//} from '@element-plus/icons-vue'
import SldCommonEmpty from "../../../components/SldCommonEmpty";
import MemberTitle from "../../../components/MemberTitle";
import areaData from "../../../assets/area.json";
import SldAddressOperation from "../../../components/AddressOperation";
import { platform } from "@/utils/config";
//import download from "downloadjs";
//import { Delete, Edit, Search, Share, Upload } from '@element-plus/icons-vue'
export default {
    name: "order-list",
    components: {
        ElInput,
        ElTimeline,
        ElTimelineItem,
        SldCommonEmpty,
        MemberTitle,
        SldAddressOperation
    },
    setup() {
        // const balance = require("../../assets/buy/balance.png");
        const store = useStore();
        const address_list = reactive({ data: [] });
        const router = useRouter();
        const route = useRoute();
        const access_token = localStorage.getItem("access_token");
        const uploadImgUrl = apiUrlSub + "api/shopp/upload/oss"; //图片上传路径
        const descImgVisible = ref(false); //备注图片上传弹窗
        const itemTableData = reactive({ data: {} }); //table中单项数据
        const itemAddImgData = reactive({ data: [] }); //table中单项新增图片数据
        const itemDelImgData = reactive({ data: [] }); //table中单项删除图片数据
        const dialogVisibleFull = ref(false);
        const dialogImageUrl = ref("");
        const areaDataArray = areaData;
        const type = ref("add");
        const cascaderProps = { label: "regionName", value: "regionCode" };
        const memberInfo = reactive({ data: store.state.memberInfo });
        const invoice_selected = require("../../../assets/buy/invoice_selected.png");
        const { proxy } = getCurrentInstance();
        const L = proxy.$getCurLanguage();
        const orderAddress = reactive({ data: "" });
        const orderSn = ref(""); //订单id
        const small = ref(true);
        const background = ref(true);
        const disabled = ref(false);
        const pageData = reactive({
            pageNum: 1,
            pageSize: 5,
            total: 0,
            goodsName: ""
        });
        let param = reactive({
            memberId: store.state.memberInfo.memberId,
            planCode: "",
            source: "",
            prayName: "",
            startDate: "",
            endDate: "",
            resource: "",
            state: "",
            pageNum: 1,
            pageSize: 5
        });
        const unit = reactive({ data: [] });
        const addressVisibleFlag = ref(false);
        const current_address_index = ref(0);
        const goodsList = reactive([
            {
                //categoryId:"",
                key: 1,
                //categoryName:"",
                skuCode: "",
                goodsName: "",
                goodsSpec: "",
                goodsMarque: "",
                goodsUnit: "",
                purchaseNum: "1",
                desc: "",
                needDate: "",
                categoryId: "",
                categoryName: "",
                purchasePic: []
            }
        ]);
        const addPlanData = ref({
            type: 0, //采购计划
            desc: "", //备注
            receivingName: "", //收货人
            receivingAddress: "",
            receivingAddressCode: "",
            receivingMobile: ""
        });
        const show_select_address = ref(false);
        const multipleSelection = ref([]);
        const selectKey = ref();
        const tableData = reactive({ data: [] });
        const dialogVisible = ref(false);
        const multipleTableRef = ref();
        const addressData = reactive({
            //lazy: true, // 开启懒加载
            // checkStrictly: true, //可选择任意节点

            //async lazyLoad(node, resolve) {
            //  // node为当前点击的节点，resolve为数据加载完成的回调(必须调用)
            //  //setTimeout(() => {
            //    const nodes = [];
            //    // 构造查询条件 => 接口入参
            //    //let params = {
            //    //  parentAreaCode: node.value, // 入参名按照自己的接口来改
            //    //};
            //    let query = {
            //      parentId: node.level == 0 ? 0 : node.value,
            //      level: node.level + 1,
            //    };
            //    proxy
            //      .$get("api/shopp/user/area/list", query)
            //      .then((res) => {
            //        if (res.code == 200) {
            //          if (res.data) {
            //            res.data.map((item) => {
            //              console.log(111);
            //              let obj = {
            //                value: item.code,
            //                label: item.name,
            //                leaf: node.level >= 2, // 当 node.level 层级达到2级时, 就不再请求接口
            //                // 具体要多少级才不请求接口, 根据层级修改
            //              };
            //              nodes.push(obj);
            //            });
            //          }

            //          resolve(nodes);
            //        } else {
            //          ElMessage(res.msg);
            //        }
            //      })
            //      .catch((e) => {
            //        //异常处理
            //        console.log(e);
            //      });
            //}, 50);
            // 请求接口
            //},
            data: areaData
        });
        const close = () => {
            addressVisibleFlag.value = false;
        };
        //弹出新建地址窗口
        const addAddress = () => {
            addressVisibleFlag.value = true;
        };
        const refreshAddress = (addressInfo, addressId) => {
            orderAddress.data = addressInfo;
            orderAddress.data.addressId = addressId;
            localStorage.setItem("addressId", orderAddress.data.addressId);
        };
        //获取地址列表
        const getAddressList = () => {
            proxy
                .$get("api/shopp/member/address/list", { uscUserId: store.state.memberInfo.uscUserId }) //旧v3/business/front/orderInfo/list
                .then((res) => {
                    if (res.code == 200) {
                        address_list.data = res.data;
                        if (res.data.length > 0 && !orderAddress.data.addressId) {
                            var default_address = res.data.filter(function (item) {
                                return item.isDefault == 1;
                            });
                            if (default_address.length > 0) {
                                orderAddress.data = default_address[0];
                            } else {
                                orderAddress.data = res.data[0];
                            }
                            if (localStorage.getItem("addressId")) {
                                let addressID = localStorage.getItem("addressId");
                                if (res.data.filter((i) => i.addressId == addressID)[0]) {
                                    orderAddress.data = res.data.filter((i) => i.addressId == addressID)[0];
                                }
                            }
                            for (let i = 0; i < address_list.data.length; i++) {
                                if (orderAddress.data.addressId == address_list.data[i].addressId) {
                                    current_address_index.value = i;
                                    break;
                                }
                            }
                        }
                        confirmOrder(1);
                    } else {
                        confirmOrder(2);
                        ElMessage(res.msg);
                    }
                })
                .catch(() => {
                    //异常处理
                });
        };
        const selectAddress = (index) => {
            current_address_index.value = index;
        };
        //确认修改地址
        const confirmChangeAddress = () => {
            orderAddress.data = address_list.data[current_address_index.value];
            show_select_address.value = false;
            confirmOrder(2);
            localStorage.setItem("addressId", orderAddress.data.addressId);
        };
        //弹出地址选择框
        const changeAddress = () => {
            getAddressList();
            show_select_address.value = true;
        };

        const onSubmit = () => {
            console.log(store.state.memberInfo, 4545);
            let arr = goodsList.map((item) => ({ ...item }));
            addPlanData.value.receivingAddress = ` ${orderAddress.data.addressAll},${orderAddress.data.detailAddress}`;
            addPlanData.value.receivingAddressCode = `${orderAddress.data.provinceCode},${orderAddress.data.cityCode},${orderAddress.data.districtCode}`;
            addPlanData.value.receivingMobile = orderAddress.data.telMobile;
            addPlanData.value.receivingName = orderAddress.data.memberName;
            arr.forEach((item) => {
                item.needDate = "";
                if (addPlanData.value.needDate) {
                    item.needDate = addPlanData.value.needDate;
                }
                if (item.purchasePic.length > 0) {
                    item.purchasePic = item.purchasePic.map((it) => it.url).join(",");
                } else {
                    item.purchasePic = "";
                }
            });
            let parms = {
                prayDate: new Date(),
                operator: store.state.memberInfo.memberMobile,
                ...addPlanData.value,
                purchaseNeedInsertRequestList: arr,
                source: platform,
                memberId: store.state.memberInfo.memberId,
                prayCode: store.state.memberInfo.memberId,
                deptCode: store.state.memberInfo.deptId,
                deptName: store.state.memberInfo.deptName
                    ? store.state.memberInfo.deptName
                    : store.state.memberInfo.parentEnterpriseName
            };

            if (!parms.receivingAddress) {
                return ElMessage.error("请填写地址");
            }
            if (!parms.needDate) {
                return ElMessage.error("请填写需求日期");
            }
            console.log(parms.purchaseNeedInsertRequestList);
            for (let i = 0; i < parms.purchaseNeedInsertRequestList.length; i++) {
                console.log(parms.purchaseNeedInsertRequestList[i]);
                if (!parms.purchaseNeedInsertRequestList[i].goodsName) {
                    ElMessage.error("请填写商品名称");
                    return;
                } else if (!parms.purchaseNeedInsertRequestList[i].goodsUnit) {
                    ElMessage.error("请选择计量单位");
                    return;
                }
            }
            if (parms.receivingAddressCode == "undefined,undefined,undefined") {
                ElMessage.error("请填写地址");
                return;
            }

            proxy
                .$post("api/shopp/memberPurchasePlan/insertPlan", parms, "json")
                .then((res) => {
                    if (res.code == 200) {
                        ElMessage({
                            message: "操作成功",
                            type: "success"
                        });
                        router.go(-1);
                    } else {
                        ElMessage(res.msg);
                    }
                })
                .catch(() => {
                    //异常处理
                });
        };
        const toDetail = () => {
            router.push({
                path: "/member/order/orderReview",
                query: {
                    orderSn: orderSn,
                    type: 2
                }
            });
        };

        const getUnitList = () => {
            proxy
                .$get("api/shopp/goods/unit", {})
                .then((res) => {
                    if (res.code == 200) {
                        unit.data = res.data;
                        //pageData.total = res.data.pagination.total;
                    } else {
                        ElMessage(res.msg);
                    }
                })
                .catch(() => {
                    //异常处理
                });
        };
        //添加商品
        const addDomain = () => {
            console.log(111);
            goodsList.push({
                key: Date.now(),
                //categoryName:"",
                goodsName: "",
                goodsSpec: "",
                goodsMarque: "",
                goodsUnit: "",
                purchaseNum: "1",
                desc: "",
                needDate: "",
                skuCode: "",
                categoryId: "",
                categoryName: "",
                purchasePic: []
            });
        };
        //删除商品
        const removeDomain = (i) => {
            console.log(1, i);
            console.log(goodsList);
            let index = goodsList.findIndex((item) => item.key == i);
            console.log(index);
            if (index !== -1) {
                goodsList.splice(index, 1);
            }
        };
        //获取单位
        const UnitChange = (value) => (i) => {
            console.log(value);
            const index = goodsList.indexOf(i);
            goodsList[index] = value;
        };
        const getModalData = () => {
            console.log(pageData);
            proxy
                .$get("api/shopp/goods/queryVague", pageData)
                .then((res) => {
                    if (res.code == 200) {
                        tableData.data = res.data.records;
                        pageData.total = res.data.total;
                    } else {
                        ElMessage(res.msg);
                    }
                })
                .catch(() => {
                    //异常处理
                });
        };
        const handleSelectionChange = (val) => {
            console.log(val);
            multipleSelection.value = val;
        };
        const openModal = (id) => {
            selectKey.value = id;
            dialogVisible.value = true;
            getModalData();
        };
        const handleSizeChange = (val) => {
            console.log(val);
            pageData.pageSize = val;
            getModalData();
            console.log(`${val} items per page`);
        };
        const handleCurrentChange = (val) => {
            console.log(val);
            pageData.pageNum = val;
            getModalData();
            console.log(`current page: ${val}`);
        };
        const onSearchGoods = () => {
            getModalData();
        };
        const addGoods = () => {
            if (multipleSelection.value.length > 1) {
                return ElMessage({
                    message: "一次只能选择一条",
                    type: "warning"
                });
            }
            const index = goodsList.findIndex((item) => item.key == selectKey.value);
            goodsList[index].goodsName = multipleSelection.value[0].skuName;
            goodsList[index].goodsSpec = multipleSelection.value[0].specStr;
            goodsList[index].goodsMarque = multipleSelection.value[0].marque;
            goodsList[index].goodsUnit = multipleSelection.value[0].marketingUnit;
            goodsList[index].skuCode = multipleSelection.value[0].skuCode;
            goodsList[index].categoryId = multipleSelection.value[0].categoryId;
            goodsList[index].categoryName = multipleSelection.value[0].categoryName;
            //  goodsList[index].purchaseNum = multipleSelection.value[0].purchaseNum;
            dialogVisible.value = false;
        };

        const addressChange = (value) => {
            console.log(value);
            //let sheng=""
            //let city=""
            //let area=""
            //for(var i=0;i<=addressData.data.length;i++){
            //  if(i.code==value[0]){
            //    sheng=i.name
            //  }
            //}
        };
        const goback = () => {
            router.go(-1);
        };
        const resetForm = () => {
            pageData.goodsName = "";
            pageData.pageNum = 1;
            getModalData();
        };

        //** 上传图片之前处理 只能上传图片格式
        const beforeUpload = (file) => {
            const isImage = file.type.startsWith("image/");
            if (!isImage) {
                ElMessage({
                    message: "只能上传图片格式文件!",
                    type: "error"
                });
            }
            return isImage;
        };

        //** 上传图片
        const handlePictureCardSuccess = (row) => {
            return (res, file, fileList) => {
                let obj = {
                    uid: "",
                    name: "",
                    url: ""
                };
                if (res.code == 200) {
                    obj.uid = file.uid;
                    obj.name = file.name;
                    obj.url = res.msg;
                    itemAddImgData.data.push(obj);
                }
            };
        };

        const handleRemove = (uploadFile, uploadFiles) => {
            itemDelImgData.data = uploadFiles;
            itemAddImgData.data = uploadFiles;
            itemTableData.data.purchasePic = uploadFiles;
            console.log(2222, itemDelImgData.data);
        };

        const handlePictureCardPreview = (uploadFile) => {
            dialogImageUrl.value = uploadFile.url;
            dialogVisibleFull.value = true;
        };

        // 备注打开单个item弹窗
        const addDescImg = (item) => {
            console.log(item, 111);
            itemDelImgData.data = [];
            itemAddImgData.data = [];
            itemTableData.data = item;
            setTimeout(() => {
                descImgVisible.value = true;
            }, 1000);
        };

        //关闭备注弹窗
        const handleCloseDesc = () => {
            descImgVisible.value = false;
            itemTableData.data.purchasePic = [...itemTableData.data.purchasePic];
        };

        //确认备注弹窗
        const handleConfirmDesc = () => {
            descImgVisible.value = false;
            if (
                itemDelImgData.data.length == 0 &&
                itemAddImgData.data.length == 0 &&
                itemTableData.data.purchasePic == 0
            ) {
                itemTableData.data.purchasePic = [];
            } else if (itemDelImgData.data.length > 0) {
                for (let index = 0; index < itemDelImgData.data.length; index++) {
                    if (itemDelImgData.data[index].response) {
                        itemDelImgData.data[index].url = itemDelImgData.data[index].response.msg;
                    }
                }
                itemTableData.data.purchasePic = itemDelImgData.data;
            } else {
                itemTableData.data.purchasePic = [...itemTableData.data.purchasePic, ...itemAddImgData.data];
            }
            console.log(itemTableData.data);
        };

        onMounted(() => {
            getUnitList();
            getAddressList();
            //if (route.query.orderState) {
            //  current_state.value = route.query.orderState;
            //} else {
            //  current_state.value = "";
            //}
            //if (route.query.evaluateState) {
            //  evaluate_state.value = route.query.evaluateState;
            //} else {
            //  evaluate_state.value = "";
            //}
            //  getTime();

            //  getCancelList();
            //  getAddressList();
        });
        router.beforeEach((to, from, next) => {
            //  if (to.query.orderState) {
            //    current_state.value = to.query.orderState;
            //  } else {
            //    current_state.value = "";
            //  }
            //  if (to.query.evaluateState) {
            //    evaluate_state.value = to.query.evaluateState;
            //  } else {
            //    evaluate_state.value = "";
            //  }
            if (to.path == "/member/procurement/procurementPlanList") {
            }

            next();
        });
        return {
            L,
            handlePictureCardSuccess,
            handleRemove,
            handlePictureCardPreview,
            handleCloseDesc,
            handleConfirmDesc,
            addDescImg,
            beforeUpload,
            descImgVisible,
            itemTableData,
            itemAddImgData,
            itemDelImgData,
            dialogVisibleFull,
            dialogImageUrl,
            uploadImgUrl,
            access_token,
            onSubmit,
            cascaderProps,
            areaDataArray,
            param,
            toDetail,
            goodsList,
            unit,
            addDomain,
            removeDomain,
            getUnitList,
            UnitChange,
            dialogVisible,
            tableData,
            handleSelectionChange,
            multipleTableRef,
            multipleSelection,
            openModal,
            pageData,
            handleSizeChange,
            small,
            disabled,
            handleCurrentChange,
            background,
            onSearchGoods,
            addGoods,
            addPlanData,
            addressData,
            goback,
            addressChange,
            orderAddress,
            selectAddress,
            confirmChangeAddress,
            changeAddress,
            show_select_address,
            close,
            addAddress,
            addressVisibleFlag,
            refreshAddress,
            type,
            address_list,
            current_address_index,
            resetForm,
            platform
        };
    }
};
</script>
<style lang="scss" scoped>
@import "../../../style/orderList.scss";

.clickBtn {
    color: $colorMain;
    cursor: pointer;
}
::v-deep .el-textarea__inner {
    padding-right: 30px;
}
.hide {
    ::v-deep .el-upload--picture-card {
        display: none;
    }
}
.disabled-upload {
    ::v-deep .el-upload--picture-card {
        opacity: 0.5; /* 设置透明度使其看起来置灰 */
        cursor: not-allowed !important;
    }
}
.sld_order_list .container h3 {
    margin-bottom: 0px;
    width: 1207px;
}
/*.el-pager li.active {
    color: $colorMain;
    cursor: default;
    font-size: 16px;
  }

  .el-pager li:hover {
    color: $colorMain;
  }

  .el-select-dropdown__item.selected {
    color: $colorMain;
  }

  .demo-form-inline .el-input {
    --el-input-width: 220px;
  }
  .el-table thead {
    color: #5f5f60;
  }
  .el-table {
    color: #2b2b2c;
  }*/

.sld_member_main_content[data-v-61d93b18] {
    background: #f8f8f8;
}
.sld_order_list {
    /*width: 1210px;*/
    width: 100%;
    background: #fff;
}
.headerDev {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 18px;
    border-bottom: 1px solid #ece9e9;
    margin-bottom: 20px;
}
.btnList {
    height: 100%;
    display: flex;
    padding-top: 42px;

    justify-content: flex-end;
    /*padding-top: 35px;*/
}
.receive_info {
    border: 1px solid #dddddd;
    border-radius: 2px;
    margin-bottom: 30px;
    .pre_message_title,
    .receive_info_title {
        height: 40px;
        line-height: 40px;
        font-size: 12px;
        padding-left: 20px;
        background-color: #f8f8f8;
        color: #666666;
    }
    .receive_info_content {
        height: 159px;
        padding: 0 40px;
    }

    .pre_message_info {
        padding: 10px 40px;
        .tag_pre {
            display: inline-block;
            line-height: 25px;
            text-align: right;
            font-weight: 400;
            margin-right: 10px;
            font-size: 14px;
            color: #333333;
            display: flex;
            min-width: 100px;
            max-width: 200px;
            justify-content: flex-end;
        }

        strong {
            font-size: 14px;
            color: $colorMain;
            margin-right: 3px;
        }
    }
    .content_left {
        width: 785px;
        /*border-right: 1px solid #dddddd;*/
        .member {
            font-size: 14px;
        }
        .address_detail {
            width: 700px;
        }
        span:not(:first-child) {
            font-size: 14px;
            line-height: 14px;
            margin-top: 22px;
        }
    }
    .content_right {
        width: 370px;
        font-size: 14px;
        border-left: 1px solid #dddddd;
        padding-left: 10px;
        .replace_address {
            color: $colorMain2;
            line-height: 14px;
        }
    }
    .add_address {
        width: 100px;
        height: 30px;
        background-color: $colorMain;
        color: white;
        line-height: 30px;
        text-align: center;
        margin-top: 29px;
        border-radius: 3px;
    }
    .nomartop {
        margin-top: 0;
    }
}
.select_address_width {
    width: 500px !important;
}
.overflow_ellipsis_clamp2 {
    width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    word-break: break-all;

    line-height: 19px;
}
.out_stock_dialog {
    /*width: 460px;*/
    margin: 0 auto;
    height: 330px;
    overflow-y: scroll;
    .good_item {
        font-size: 14px;

        img {
            width: 80px;
            height: 80px;
        }
        .good_info {
            margin-left: 10px;
        }
        .good_name {
            width: 320px;
            color: #333333;
            line-height: 14px;
            margin-top: 10px;
            display: inline-block;
        }
        .spec_num {
            margin-top: 26px;
            .good_spec {
                color: #999999;
            }
            .good_num {
                float: right;
                color: #333333;
            }
        }
    }
}
::v-deep .el-dialog__header {
    // background-color: $colorMain;
    /*padding: 11px 20px;*/
    display: flex;
    align-items: center;
    justify-content: space-between;
    ::v-deep .el-dialog__title {
        color: #fff;
        font-size: 14px;
    }
    ::v-deep .el-dialog__headerbtn {
        top: auto;
    }
    ::v-deep .el-dialog__headerbtn .el-dialog__close {
        color: #fff !important;
        font-size: 18px;
    }
}
::v-deep .el-dialog {
    overflow: hidden;
    border-radius: 4px;
}
::v-deep .el-dialog__footer {
    display: flex;
    // justify-content: center;
    align-items: center;
}
.aft_btn:hover {
    background-color: rgba($color: #f30213, $alpha: 0.2);
    color: #f30213;
}
.aft_btn:focus {
    background-color: #fff;
    color: #333333;
    border-color: #dcdfe6;
}
.address_con {
    height: 330px;
    overflow-y: scroll;
    scrollbar-width: none !important;
    -ms-overflow-style: none !important;
    .address_item {
        &:not(:first-child) {
            margin-top: 20px;
        }
        width: 458px;
        //    height: 104px;
        box-sizing: border-box;
        border: 1px solid #dfdfdf;
        position: relative;
        padding: 20px;
        span,
        div:not(:first-child) {
            margin-top: 12px;
        }
        .address_text {
            display: flex;
            width: 400px;
        }
        .selected {
            width: 14px;
            height: 14px;
            position: absolute;
            right: 0;
            bottom: 0;
        }
    }
    .select {
        border: 1px solid $colorMain2;
    }
}
.red {
    background-color: $colorMain;
    color: white;
}
.remove {
    width: 120px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    border-radius: 3px;
    background-color: $colorMain;
    margin-left: 10px;
    color: white;
    cursor: pointer;
}
.showContent {
    margin-top: 20px;
    padding: 10px;
    background: rgb(250, 247, 247);
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
}
.Submit {
    background: #0e3177;
    width: 70px;
    height: 40px;
    border-radius: 4px;
    text-align: center;
    color: #fff;
    margin-left: 10px;
    line-height: 40px;
    font-size: 14px;
    cursor: pointer;
}
.search {
    background: #0e3177;
    width: 70px;
    height: 31px;
    border-radius: 4px;
    text-align: center;
    color: #fff;
    margin-left: 10px;
    line-height: 31px;
    font-size: 14px;
    cursor: pointer;
}
.choose {
    background: #0e3177;
    width: 80px;
    height: 32px;
    border-radius: 4px;
    text-align: center;
    color: #fff;
    line-height: 32px;
    /*font-size: 14px;*/
    margin-bottom: -10px;
    cursor: pointer;
    font-size: 13px;
}
.delet {
    background: #0e3177;
    width: 70px;
    height: 32px;
    border-radius: 4px;
    text-align: center;
    color: #fff;
    margin-left: 10px;
    line-height: 32px;
    font-size: 13px;
    cursor: pointer;
    font-size: 12px;
}
.demo-dynamic {
    margin-bottom: 20px;
}
.addGoods {
    width: 100%;
    border: 1px solid#0e3177;
    border-radius: 4px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    /*font-size: 12px;*/
    color: #0e3177;
    cursor: pointer;
}
::v-deep .el-form-item {
    margin-bottom: 0;
    .el-radio {
        margin-bottom: 0;
    }
    .el-radio__label {
        margin-top: 0;
        line-height: 0;
    }
    .el-radio:last-child {
        display: flex;
        align-items: center;
    }
}
.Clear {
    width: 70px;
    height: 40px;
    border-radius: 4px;
    text-align: center;
    color: #5e5858;
    line-height: 40px;
    font-size: 14px;
    border: 1px solid #ccc;
    cursor: pointer;
}
.Clear2 {
    cursor: pointer;
    width: 70px;
    height: 31px;
    border-radius: 4px;
    text-align: center;
    color: #5e5858;
    line-height: 31px;
    font-size: 14px;
    border: 1px solid #ccc;
}
::v-deep .el-radio__input.is-checked + .el-radio__label {
    color: #03499e;
    height: 20px;
    line-height: 19px;
}
::v-deep .el-radio__input.is-checked .el-radio__inner {
    border-color: #03499e;
    background: #03499e;
}
::v-deep .el-checkbox__input.is-checked .el-checkbox__inner {
    background: #03499e;
    border-color: #03499e;
}
::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
    background: #03499e;
}
::v-deep .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background: #03499e;
    border-color: #03499e;
}
/*.addGoods :hover{
    background:#0e3177;
    color:#fff;
  }*/
.container2 {
    margin-top: 20px;
}
.el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #04488c;
    color: var(--el-color-white);
}
/*::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
      background-color: #04488c;
      color: var(--el-color-white);
  }
  ::v-deep .el-radio__input.is-checked .el-radio__inner {
      border-color:#04488c;
      background: #04488c;
  }
  ::v-deep .el-radio__input.is-checked+.el-radio__label {
      color: #04488c;
  }*/
::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #04488c;
}
::v-deep .el-radio__input.is-checked .el-radio__inner {
    border-color: #04488c;
    background: #04488c;
}
::v-deep .el-radio__input.is-checked + .el-radio__label {
    color: #04488c;
}
// ::v-deep .el-dialog__title{
//     color:#fff;
// }
// ::v-deep .el-dialog__headerbtn .el-dialog__close {
//     color: #fff;
// }
::v-deep .el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #04488c;
    border-color: #04488c;
}
::v-deep .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #04488c;
    border-color: #04488c;
}
::v-deep .el-form--inline .el-form-item {
    margin-right: 0px;
}
</style>
